import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AdsenseResponsive from "../../components/adsenseResponsive"

const Icons = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.icons" })} />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "icons.category" })}
        </h3>
        <div className="images grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3 md:gap-3 mb-8">
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/all" className="flex p-2">
              <img src="/images/icons/all.svg" className="h-16"></img>
              <div className="pl-3 flex items-center font-bold">
                {intl.formatMessage({ id: "icons.all" })}
              </div>
            </Link>
          </div>
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/lifestyle" className="flex p-2">
              <img src="/images/icons/music_1.svg" className="h-16"></img>
              <div className="pl-3 flex items-center font-bold">
                {intl.formatMessage({ id: "icons.lifestyle" })}
              </div>
            </Link>
          </div>
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/business" className="flex p-2">
              <img src="/images/icons/terminal_1.svg" className="h-16"></img>
              <div className="pl-3 flex items-center font-bold">
                {intl.formatMessage({ id: "icons.business" })}
              </div>
            </Link>
          </div>
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/food" className="flex p-2">
              <img src="/images/icons/egg_2.svg" className="h-16"></img>
              <div className="pl-3 flex items-center font-bold">
                {intl.formatMessage({ id: "icons.food" })}
              </div>
            </Link>
          </div>
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/transportation" className="flex p-2">
              <img src="/images/icons/rocket_1.svg" className="h-16"></img>
              <div className="pl-3 flex items-center font-bold">
                {intl.formatMessage({ id: "icons.transportations" })}
              </div>
            </Link>
          </div>
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/kids" className="flex p-2">
              <img src="/images/icons/pants_1.svg" className="h-16"></img>
              <div className="pl-3 flex items-center font-bold">
                {intl.formatMessage({ id: "icons.kids" })}
              </div>
            </Link>
          </div>
          <div className="image bg-white rounded border border-gray-400 hover:shadow">
            <Link to="/icons/symbol" className="flex p-2">
              <img src="/images/icons/hiragana_1.svg" className="h-16"></img>
              <div className="pl-3 flex items-center font-bold">
                {intl.formatMessage({ id: "icons.symbol" })}
              </div>
            </Link>
          </div>
        </div>
        {/* <A8 /> */}
        <div className="w-full h-40">{/* <AdsenseResponsive /> */}</div>
      </section>
    </Layout>
  )
}
export default Icons
