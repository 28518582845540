import React, { useEffect } from "react"

const AdsenseResponsive = () => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [])

  return (
    <div className="mb-6">
      {/* ディスプレイ広告 */}
      {/* <!-- flat-svg-designs-responsive --> */}
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-5843269452604762"
        data-ad-slot="5032173552"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  )
}

export default AdsenseResponsive
